<template>
  <div class="fixed w-screen h-20 top-0 mt-20">
    <div class="bg-gray-500 py-4 w-full z-10">
      <div class="container w-full flex items-baseline">
        <button
          class="py-2 focus:outline-none whitespace-no-wrap"
          @click="showAdvanced = !showAdvanced"
          v-on-clickaway="hideadvancedsearch"
        >
          <span>{{ storeCategories[activeCategory].label }}</span>
          <i
            class="mdi "
            :class="showAdvanced ? 'mdi-chevron-down' : 'mdi-chevron-right'"
          ></i>
        </button>
        <div
          class="border-gray-700 bg-white ml-6 py-2 px-4 rounded-sm flex flex-grow"
        >
          <i class="mdi mdi-magnify text-xl text-gray-400"></i>
          <input class=" focus:outline-none flex-grow px-6" />
        </div>
      </div>
    </div>
    <transition name="advanced">
      <div class="w-full bg-white shadow-md z-0" v-if="showAdvanced">
        <div class="container py-4">
          <ul class="grid grid-cols-3 md:grid-cols-4 gap-2">
            <li
              class="w-full cursor-pointer whitespace-no-wrap text-sm md:text-base"
              v-for="(sc, i) in storeCategories"
              :key="i"
              @click="activeCategory = i"
            >
              <span class="text-primary-500 mr-2 ">
                <i
                  class="mdi "
                  :class="
                    activeCategory == i ? 'mdi-circle' : 'mdi-circle-outline'
                  "
                ></i
              ></span>
              {{ sc.label }}
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapState } from "vuex";
export default {
  mixins: [clickaway],
  data() {
    return {
      showAdvanced: false,
      activeCategory: 0
    };
  },
  computed: {
    ...mapState("createstore", ["storeCategories"])
  },
  methods: {
    hideadvancedsearch() {
      this.showAdvanced = false;
    }
  }
};
</script>

<style></style>
