<template>
  <div>
    <search></search>
    <main class="container px-2 md:px-0 pt-24">
      <h1 class="text-2xl mb-4">In Evidenza</h1>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12 mb-4">
        <div
          class="card w-full flex flex-col"
          v-for="(high, h) in products"
          :key="h"
        >
          <router-link
            :to="`/${$route.params.lang}/products/${high.product_id}`"
            class="border w-full overflow-hidden flex justify-center items-center rounded-md"
          >
            <img
              :src="high.product_photo"
              class="w-full h-48 object-cover object-center"
              v-if="high.product_photo"
            />
            <q-default-img
              class="w-full h-48 object-contain"
              v-else
            ></q-default-img>
          </router-link>
          <h2 class="">{{ high.product_name }}</h2>
          <router-link
            :to="`/${$route.params.lang}/store/${high.company_id}`"
            class="text-gray-400 flex-grow"
            >{{ high.company_name }}</router-link
          >
          <div class="flex flex-col md:flex-row items-baseline mt-3">
            <p class="font-bold flex-grow">
              {{ high.single_unit_price | price }}
            </p>
            <button
              class="text-secondary-500 border border-secondary-500 text-sm px-4 rounded-md hover:bg-secondary-500 hover:text-white"
              @click="addItem(high)"
            >
              Aggiungi <i class="mdi mdi-cart-arrow-down text-xl "></i>
            </button>
          </div>
        </div>
      </div>
      <div
        class="w-full flex justify-center items-center py-8 border-b border-t mt-20"
        v-if="this.$store.state.products.nextKey"
      >
        <button class="btn btn-cta" @click="loadProducts({ loadMore: true })">
          Vedi di più
        </button>
      </div>
    </main>

    <section class="mt-24">
      <h1 class="text-5xl font-bold text-center ">Come funziona Hive</h1>
      <div class="w-full flex flex-col md:flex-row justify-center items-center">
        <div
          class="flex flex-col w-full md:w-1/4 items-center mt-12"
          v-for="(cf, i) in comeFunziona"
          :key="i"
        >
          <div
            class="w-40 h-40 bg-gray-300 rounded-full flex justify-center items-center"
          >
            <img :src="getImgUrl(cf.icon)" />
          </div>
          <h4
            class="w-12 h-12 p-2 my-8 rounded-full flex justify-center items-center bg-primary-500 text-white text-xl"
          >
            {{ i + 1 }}
          </h4>
          <h1 class="font-bold text-3xl">{{ cf.title }}</h1>
          <p class="text-gray-600 text-center w-full">
            {{ cf.text }}
          </p>
        </div>
      </div>
      <div class="w-full flex justify-center items-center mt-12">
        <router-link
          :to="`/${$route.params.lang}/about`"
          class="text-center btn text-secondary-500 border border-secondary-500"
          >Scopri di pi&ugrave;</router-link
        >
      </div>
    </section>

    <div
      class="w-full flex justify-center items-center py-8 border-b border-t mt-20"
    >
      <router-link
        :to="{ path: `/${$route.params.lang}/store/create` }"
        class="btn btn-cta"
      >
        Crea il tuo negozio
      </router-link>
    </div>
    <!-- <router-link :to="{ path: 'users' }">Go to Users</router-link> -->
    <!--<router-link :to="{ path: 'contact' }">Go to Contact</router-link>-->
    <!--<router-link :to="{ path: 'projects' }">Go to Projects</router-link>-->
  </div>
</template>
<script>
import { SEOResource } from "../app-seo.js";
import { mapActions, mapMutations, mapState } from "vuex";
import Search from "@/_components/Search";
export default {
  name: "home",
  components: {
    Search
  },
  data() {
    return {
      info: null,

      comeFunziona: [
        {
          title: "Acquistare",
          text: "Acquistare ogni tipo di prodotto o servizio",
          icon: "cf_1"
        },
        {
          title: "Vendere",
          text: "Creare il tuo negozio e vendere i tuoi prodotti",
          icon: "cf_2"
        },
        {
          title: "Monitorare",
          text: "Aggiungere strumenti pensati per il tuo Business",
          icon: "cf_3"
        }
      ]
    };
  },

  async mounted() {
    // this.$store.dispatch('home/setStatus');
    this.$store.dispatch("home/seo");
    this.info = this.$store.getters["home/items"];
    // this.$store.dispatch("auth/getCurrentUser");
    try {
      this.loadProducts({
        next_key: this.$store.state.products.nextKey,
        loadMore: false
      });
      // let home = await axios.get(
      //   "https://next.json-generator.com/api/json/get/EkT330VFK"
      // );
      // this.highlights = home.data;
      // console.log("Dati Home  ", home.data);
    } catch (error) {
      console.log("Errore home ", error);
    }
  },
  beforeDestroy() {
    this.SET_NEXT_KEY(null);
    console.log(
      "%cDistruggo la next key",
      "color:lime;font-size:2rem",
      this.$store.state.products.nextKey
    );
  },
  computed: {
    ...mapState("products", ["products"]),
    loading: {
      get: function() {
        let _ls = this.$store.getters["home/loadingStatus"];
        //this.show = _ls === "LOADED" || _ls === "READY";
        //this.color = _ls === "LOADED" ? "#008000" : "#000";
        return _ls;
      },
      set: function(newValue) {
        this.SET_STATUS(newValue);
      }
    },
    show() {
      return this.loading === "LOADED" || this.loading === "READY";
    },
    color() {
      return this.loading === "LOADED" ? "#008000" : "#000";
    },
    user: {
      get: function() {
        return this.$store.getters["auth/currentUserData"];
      }
    }
  },
  metaInfo() {
    return new SEOResource().SEOData(this);
  },
  methods: {
    ...mapMutations(["home/SET_STATUS"]),
    ...mapMutations("products", ["SET_NEXT_KEY"]),
    ...mapActions("cart", ["addItem"]),
    ...mapActions("products", ["loadProducts"]),
    getImgUrl(img) {
      return require("@/_assets/images/" + img + ".svg");
    }
  }
};
</script>
<style></style>
